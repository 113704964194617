import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="messages"></div>
      <script src="anime.js"></script>
      <script src="scripts.js"></script>
    </div>
  );
}

export default App;
